import React from "react"
import styled from "styled-components"
import { PulseLoader } from "react-spinners"

export const Block = styled.div`
  width: ${({ w }) => w}px;
  flex: none;
`

export const RedButton = styled.button`
  box-sizing: border-box;
  border-radius: 20px;
  color: rgba(255, 255, 255, 1);
  font-weight: 600;
  padding: 7px 22px;
  text-decoration: none;
  font-size: 14px;
  transition: all 0.2s;
  &:hover {
    color: rgba(255, 255, 255, 1);
    text-decoration: none !important;
    box-shadow: ${({ disabled = false }) =>
      disabled ? "" : "0px 10px 8px -6px rgba(0, 0, 0, 0.16)"};
    background-color: ${({ disabled = false }) =>
      disabled ? "#ccc" : "#ff2300"};
  }
  border: none;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
  min-width: 138px;
  height: 33px;
  background-color: ${({ disabled = false }) =>
    disabled ? "#ccc" : "#ff2300"};
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.34);
`

const LoadingButtonWrapper = styled.div`
  box-sizing: border-box;
  border-radius: 17px;
  color: rgba(255, 255, 255, 1);
  line-height: 19px;
  font-weight: 800;
  padding: 7px 17px;
  text-decoration: none;
  font-size: 19px;
  &:hover {
    color: rgba(255, 255, 255, 1);
    text-decoration: none !important;
  }
  border: none;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
  min-width: 138px;
  background-color: rgba(255, 35, 0, 1);
  text-align: center;
`

export const LoadingButton = props => (
  <LoadingButtonWrapper {...props}>
    <PulseLoader sizeUnit={"px"} size={10} color={"#fff"} />
  </LoadingButtonWrapper>
)

export const RedButtonWLoading = React.forwardRef(
  ({ loading = false, children, ...props }, ref) => {
    const loadingStyle = { fontSize: 0 }
    return (
      <RedButton ref={ref} {...props} style={loading ? loadingStyle : {}}>
        {loading ? (
          <PulseLoader sizeUnit="px" size={10} color="#fff" />
        ) : (
          children
        )}
      </RedButton>
    )
  }
)

// 蓝湖 红色标注
export const H1 = styled.div`
  font-size: 36px;
  color: rgba(0, 0, 0, 1);
  line-height: 59px;
  font-weight: 800;
  text-align: center;
`
export const HSpan1 = styled.span`
  font-size: 36px;
  color: rgba(0, 0, 0, 1);
  line-height: 59px;
  font-weight: 800;
  text-align: center;
`

// 蓝湖 橘色标注
export const H2 = styled.div`
  font-size: 20px;
  color: rgba(0, 0, 0, 1);
  line-height: 29px;
  font-weight: 800;
`

export const HSpan2 = styled.span`
  font-size: 20px;
  color: rgba(0, 0, 0, 1);
  line-height: 29px;
  font-weight: 800;
`

// 蓝湖 黄色标注
export const H3 = styled.div`
  font-size: 34px;
  color: rgba(0, 0, 0, 1);
  line-height: 59px;
  font-weight: 800;
  text-align: center;
`

export const HSpan3 = styled.span`
  font-size: 34px;
  color: rgba(0, 0, 0, 1);
  line-height: 59px;
  font-weight: 800;
  text-align: center;
`

// 最常用的 14px
// 蓝湖标注 灰色
export const H4 = styled.div`
  font-size: 14px;
  font-weight: 300;
  color: rgba(0, 0, 0, 1);
  line-height: 23px;
`

export const HSpan4 = styled.span`
  font-size: 14px;
  font-weight: 300;
  color: rgba(0, 0, 0, 1);
  line-height: 23px;
`

// 蓝湖标注 绿色
export const HSpan5 = styled.span`
  font-size: 14px;
  font-weight: 300;
  color: rgba(129, 129, 129, 1);
  line-height: 19px;
`
export const H5 = styled.div`
  font-size: 14px;
  font-weight: 300;
  color: rgba(129, 129, 129, 1);
  line-height: 19px;
`

// 12px link
export const HAnchor5 = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  color: #0068ff;
`

export const Spacer = styled.div`
  height: ${props => props.h}px;
`

export const Line = styled.div`
  width: calc(100% - ${({ wp = 0 }) => wp * 2}px);
  height: 1px;
  background-color: #e3e3e3;
  margin: 0 auto;
`

export const TheBox = styled.div`
  max-width: ${({ w = 506 }) => w}px;
  margin: 0 auto;
`

// 21px
export const H21 = styled.div`
  font-size: 21px;
  color: rgba(0, 0, 0, 1);
  line-height: 34px;
  font-weight: 800;
  text-align: center;
`
