import React, { useState, useEffect } from "react"
import Layout from "@/components/layout"
import SEO from "@/components/seo"
import { useIntl } from "react-intl"
import Axios from "axios"
import * as S from "@/components/Shop"
import useBigScreen from "@/hooks/bigScreen"
import styled from "styled-components"
import LogoLight from "@/images/SVG/logo_light.svg"
import LogoAir from "@/images/SVG/logo_air.svg"
import {
  specs_light_tinified,
  specs_air_tinified,
} from '@/images/index'

const SpecsContainer = styled.div`
  position: relative;
  width: calc(100% - 40px);
  max-width: 1000px;
  height: auto;
  min-height: 100vh;
  margin: 0 auto;
  background-color: rgba(0, 0, 0, 0);
  color: black;
  font-size: 14px;
  letter-spacing: 0.05em;
  @media screen and (max-width: 480px) {
		letter-spacing: normal;
	}
}
`
const Title = styled.div`
  font-size: 40px;
  font-weight: bold;
  color: #000;
  text-align: center;
  margin: 0 auto;
`
const Table = styled.div`
  width: 100%;
  height: auto;
  text-align: center;
`
const Row = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  min-height: 50px;
  margin: 30px 0;
`
const Logo = styled.div`
  position: relative;
  width: 50%;
  height: auto;
  display: inline-block;
`
const ImageW = styled.div`
  position: relative;
  width: 50%;
  height: auto;
  display: inline-block;
`
const ImageC = styled.img`
  width: calc(100% - 10px);
  height: auto;
  margin: 5px;
`
const RowTitle = styled.div`
  width: 100%;
  height: auto;
  line-height: 24px;
  margin-top: 10px 0;
  /* 	background-color: #e9e9e9; */
  display: inline-block;
  font-size: 18px;
  font-weight: bold;
  vertical-align: middle;
  padding-bottom: 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  text-align: left;
`
const RowContent = styled.div`
  width: 100%;
  height: auto;
  /* 	background-color: #558671; */
  display: inline-block;
  vertical-align: middle;
  line-height: 24px;
  color: #3d3d3d !important;
`
const RowContentL = styled.div`
  width: calc(50% - 0px);
  height: auto;
  /* 	background-color: #adc3ba; */
  display: inline-block;
  padding: 10px 20px;
  vertical-align: top;
`
const RowContentR = styled.div`
  width: calc(50% - 0px);
  height: auto;
  /* 	background-color: #7bddb3; */
  display: inline-block;
  padding: 10px 20px;
  vertical-align: top;
`
const Explication = styled.div`
  font-size: 12px;
  text-align: left;
`
const Index = () => {
  const intl = useIntl()
  // table data
  const [tbdata, setTbdata] = useState([])
  const getJson = () => {
    Axios.get(`${process.env.resourceUrl}docs/specs/specs1.json`
    ).then(function (data) {
      setTbdata(data.data[intl.locale])
    })
  }

  useEffect(
    function () {
      getJson()
    },
    [intl.locale]
  )
  return (
    <Layout menuBlack={true}>
      <SEO page="Specifications" />
      <SpecsContainer>
        <S.Spacer h={130} />
        <Title>{intl.formatMessage({ id: `specsPage.text1` })}</Title>
        {tbdata.length > 0 ? (
          <Table>
            <Row>
              <Logo>
                <LogoLight
                  style={{
                    width: "40%",
                    verticalAlign: "center"
                  }}
                />
              </Logo>
              <Logo
                style={{
                  position: "relative",
                  top: "6px"
                }}>
                <LogoAir
                  style={{
                    width: "50%",
                    verticalAlign: "center"
                  }}
                />
              </Logo>
            </Row>
            <Row>
              <ImageW>
                <ImageC src={specs_light_tinified} />
              </ImageW>
              <ImageW>
                <ImageC src={specs_air_tinified} />
              </ImageW>
            </Row>
            {tbdata.map((item, index1) => {
              return (
                <Row key={`${index1}index1`}>
                  <RowTitle>{item.title}</RowTitle>
                  <RowContent>
                    <RowContentL>
                      {item.light.map((item, index2) => {
                        return (
                          <React.Fragment key={`${index2}index2`}>
                            {item}
                            <br />
                          </React.Fragment>
                        )
                      })}
                    </RowContentL>
                    <RowContentR>

                      {item.air.map((item, index3) => {
                        return (
                          <React.Fragment key={`${index3}index3`}>
                            {item}
                            <br />
                          </React.Fragment>
                        )
                      })}
                    </RowContentR>
                  </RowContent>
                </Row>
              )
            })}
            <Row>
              <Explication>
                {intl.formatMessage({ id: `specsPage.text2` })}
              </Explication>
            </Row>
          </Table>
        ) : (
          ""
        )}
        <S.Spacer h={300} />
      </SpecsContainer>
    </Layout>
  )
}

export default Index
